// Dependencies
import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from '../components/Helmet'
import { Parallax } from 'react-parallax'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

// Components
import ScaleDown from '../components/Animation/ScaleDown'
import FadeIn from '../components/Animation/FadeIn'
import Reveal from '../components/Animation/Reveal'
import Header from '../components/Header/InternalHeader'
import ProfileSkills from '../components/About/ProfileSkills'
import ProfileHistory from '../components/About/ProfileHistory'
import ProfileBrands from '../components/About/ProfileBrands'
import ProfileBackground from '../components/About/ProfileBackground'
import ProjectsList from '../components/Work/ProjectsList'
import Layout from '../components/layout'

// Styles
import {
  Section,
  SectionTitle,
  CtaLink as Cta,
} from '../components/common/Section.styled'
import {
  AboutContent,
  AboutHeader,
  AboutHeaderContent,
  ProfileIntro,
  ProfilePicture,
  ProfileImage,
  Squares,
} from '../components/About/About.styled'

const About = props => {
  const siteInfo = get(props, 'data.allContentfulGeneral.nodes[0]')

  const introTitle = get(props, 'data.allContentfulAbout.nodes[0].introTitle')
  const introText = get(
    props,
    'data.allContentfulAbout.nodes[0].introText.childMarkdownRemark.rawMarkdownBody'
  )
  const skillsTitle = get(props, 'data.allContentfulAbout.nodes[0].skillsTitle')
  const skillsText = get(
    props,
    'data.allContentfulAbout.nodes[0].skillsText.childMarkdownRemark.rawMarkdownBody'
  )
  const skillsTags = get(props, 'data.allContentfulAbout.nodes[0].skillsTags')
  const jobsTitle = get(props, 'data.allContentfulAbout.nodes[0].jobsTitle')
  const jobsHistory = get(props, 'data.allContentfulAbout.nodes[0].jobsHistory')
  const brandsTitle = get(props, 'data.allContentfulAbout.nodes[0].brandsTitle')
  const brandsText = get(
    props,
    'data.allContentfulAbout.nodes[0].brandsText.childMarkdownRemark.rawMarkdownBody'
  )
  const brandsLogos = get(props, 'data.allContentfulAbout.nodes[0].brandsLogos')
  const profilePicture = get(
    props,
    'data.allContentfulAbout.nodes[0].profilePicture'
  )
  const heroPicture = get(props, 'data.allContentfulAbout.nodes[0].heroPicture')
  const projects = get(props, 'data.allContentfulProject.nodes')

  return (
    <Layout>
      <div>
        <Helmet
          {...{ ...siteInfo, websiteTitle: siteInfo.websiteTitle + ' - About' }}
        />
        <div>
          <div>
            <Header logoSvg={siteInfo.logoSvg.logoSvg} />
            <Section>
              <br />
              <br />
              <br />
              <ScaleDown>
                <SectionTitle>about</SectionTitle>
              </ScaleDown>
            </Section>
            <FadeIn>
              <AboutHeader>
                <Parallax
                  bgImage={heroPicture.fluid.src}
                  bgImageSizes={heroPicture.fluid.sizes}
                  bgImageSrcSet={heroPicture.fluid.srcSet}
                  strength={200}
                >
                  <AboutHeaderContent />
                </Parallax>
              </AboutHeader>
            </FadeIn>
            <Section>
              <AboutContent>
                <ProfileIntro>
                  <ProfileBackground title={introTitle} text={introText} />
                  <br />
                  <br />
                  <ProfileSkills
                    title={skillsTitle}
                    text={skillsText}
                    skills={skillsTags}
                  />
                </ProfileIntro>
                <ProfilePicture>
                  <FadeIn>
                    <ProfileImage fluid={profilePicture.fluid} />
                    <Squares />
                  </FadeIn>
                </ProfilePicture>
              </AboutContent>
              <AboutContent>
                <ProfileBrands
                  title={brandsTitle}
                  text={brandsText}
                  brands={brandsLogos}
                />
                <ProfileHistory title={jobsTitle} history={jobsHistory} />
              </AboutContent>
              <br />
              <br />
              <br />
              <ProjectsList
                projects={projects}
                title="Projects"
                shouldAllowExtra={false}
                compact
              />
              <Reveal fluid>
                <Cta
                  to="/work"
                  href
                  onClick={() => {
                    trackCustomEvent({
                      category: 'Extra Section Button',
                      action: 'Click',
                      label: 'See all projects',
                    })
                  }}
                >
                  See all projects
                </Cta>
              </Reveal>
            </Section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query AboutQuery {
    allContentfulGeneral {
      nodes {
        ...general
      }
    }

    allContentfulAbout {
      nodes {
        ...about
      }
    }

    allContentfulProject {
      nodes {
        ...project
      }
    }
  }
`
