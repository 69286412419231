// Dependencies
import React from 'react'
import PropTypes from 'prop-types'

// Components
import Reveal from '../Animation/Reveal'
import FadeIn from '../Animation/FadeIn'

// Styles
import { Skills } from '../About/About.styled'

const ProfileSkills = ({ title, text, skills }) => {
  return (
    <Skills>
      <div>
        <Reveal>
          <h3>{title}</h3>
        </Reveal>
      </div>
      <br />
      <div>
        <Reveal>
          {text &&
            text.split('\n').map((p, i) => {
              return (
                <p
                  key={'skills_p' + i}
                  dangerouslySetInnerHTML={{ __html: p }}
                />
              )
            })}
        </Reveal>
      </div>
      <div>
        <ul>
          {skills &&
            skills.map((skill, index) => {
              return (
                <li key={'skills' + index}>
                  <FadeIn>
                    <span>{skill}</span>
                  </FadeIn>
                </li>
              )
            })}
        </ul>
      </div>
    </Skills>
  )
}

// Components PropTypes
ProfileSkills.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  skills: PropTypes.arrayOf(PropTypes.string),
}
ProfileSkills.defaultProps = {
  skills: [],
  text: '',
}

export default ProfileSkills
