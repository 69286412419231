// Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

// Components
import Reveal from '../Animation/Reveal'

// Styles
import { CtaLink as Cta } from '../common/Section.styled'

const ProfileBackground = ({ title, text, showCta }) => {
  return (
    <>
      <div>
        <Reveal>
          <h3>{title}</h3>
        </Reveal>
      </div>
      <br />
      <div>
        <Reveal>
          {text &&
            text.split('\n').map((p, i) => {
              return (
                <p
                  key={'background_p' + i}
                  dangerouslySetInnerHTML={{ __html: p }}
                />
              )
            })}
        </Reveal>
      </div>
      {showCta && (
        <Reveal fluid>
          <Cta
            to="/about"
            href
            onClick={() => {
              trackCustomEvent({
                category: 'Home Button',
                action: 'Click',
                label: 'See full profile',
              })
            }}
          >
            See full profile
          </Cta>
        </Reveal>
      )}
    </>
  )
}

// Components PropTypes
ProfileBackground.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  showCta: PropTypes.bool,
}
ProfileBackground.defaultProps = {
  showCta: false,
  text: '',
}

export default ProfileBackground
