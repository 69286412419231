import styled from 'styled-components'
import Image from 'gatsby-image'
import colors from '../../constants/colors'
import { sourceCodePro } from '../../constants/fonts'
import { sizesMin, sizesMax } from '../../constants/breakpoints'

export const AboutContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 5;
  position: relative;

  @media screen and (max-width: ${sizesMax.tablet}) {
    flex-direction: column-reverse;
  }
`

export const AboutHeader = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: block;
  position: relative;

  .react-parallax {
    width: 100%;
    height: 100%;

    .react-parallax-background-children {
      width: 100%;

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;

        img {
          font-family: 'object-fit: contain; object-position: center center;';
        }
      }
    }
  }

  @media screen and (max-width: ${sizesMax.tablet}) {
    display: none;
  }
`

export const AboutHeaderContent = styled.div`
  max-height: 576px;
  position: relative;
  display: block;

  height: 40vw;
  @media screen and (max-width: ${sizesMax.tablet}) {
    height: 60vw;
  }
`

export const ProfileIntro = styled.div`
  width: 60%;
  overflow: hidden;
  position: relative;
  z-index: 10;

  @media screen and (max-width: ${sizesMax.tablet}) {
    width: 100%;
  }
`

export const ProfilePicture = styled.div`
  width: 25%;
  position: relative;
  height: 100%;

  @media screen and (max-width: ${sizesMax.tablet}) {
    width: 40%;
    margin-bottom: 50px;
    align-self: center;
  }

  @media screen and (max-width: ${sizesMax.mobileL}) {
    width: 60%;
    align-self: center;
  }
`

export const ProfileImage = styled(Image)`
  position: relative;
  z-index: 10;
  display: block;
  width: 100%;
  height: 100%;
  border-bottom: inset 7px ${colors.lightViolet};
`

export const Squares = styled.div`
  z-index: 5;
  transform: rotate(-45deg);

  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  content: '';
  border: solid 2px ${colors.lighterViolet};

  :after,
  :before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    content: '';
    border: solid 2px ${colors.violet};
    z-index: 5;
  }

  :after {
    top: calc(50% - 4px);
    left: calc(-50% - 8px);
    width: calc(50% + 2px);
    height: calc(50% + 2px);
    margin-left: -20px;
  }

  :before {
    top: calc(50% - 4px);
    left: calc(100% + 2px);
    width: calc(50% + 2px);
    height: calc(50% + 2px);
    margin-left: 20px;
  }
`

export const Brands = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-top: 35px;

  @media screen and (max-width: ${sizesMax.tablet}) {
    width: 100%;
  }

  ul {
    position: relative;
    z-index: 6;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;

    li {
      figure {
        position: relative;
        background: ${colors.shadow};
        z-index: 2;
        display: flex;
        height: 100px;
        padding: 10px 0;

        @media (max-width: ${sizesMax.tablet}) {
          padding: 8px 0;
        }

        @media (max-width: ${sizesMax.mobileL}) {
          padding: 5px 0;
        }
      }

      figure:after {
        content: '';
        background: ${colors.shadow};
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: -1;
      }

      @media screen and (min-width: ${sizesMin.tablet}) {
        width: calc(25% - (30px / 4));
        margin-right: calc(30px / 3);
        margin-bottom: calc(30px / 3);

        &:nth-child(4n),
        &:last-child {
          margin-right: 0;
        }
      }

      @media screen and (min-width: ${sizesMin.mobileL}) and (max-width: ${sizesMax.tablet}) {
        width: calc((100% / 3) - (20px / 3));
        margin-right: calc(20px / 2);
        margin-bottom: calc(30px / 3);

        &:nth-child(3n),
        &:last-child {
          margin-right: 0;
        }
      }

      @media screen and (max-width: ${sizesMax.mobileL}) {
        width: calc((100% / 2) - (20px / 2));
        margin-right: calc(20px / 2);
        margin-bottom: calc(30px / 3);

        &:nth-child(2n),
        &:last-child {
          margin-right: 0;
        }
      }

      figure div {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
`
export const History = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  margin-top: 35px;

  @media screen and (max-width: ${sizesMax.tablet}) {
    width: 100%;
  }

  ul {
    position: relative;
    z-index: 6;
    display: block;
    padding-left: 20px;

    :before {
      position: absolute;
      width: 2px;
      height: 100%;
      left: 5px;
      top: 0;
      background: ${colors.lighterViolet};
      content: '';
    }
  
    li {
      position: relative;
      z-index: 6;
      display: block;
      margin-top: 10px;
      padding: 10px;

      :before {
        position: absolute;
        width: 10px;
        height: 10px;
        left: -21px;
        top: 10px;
        background: ${colors.lighterViolet};
        border-radius: 20px;
        border: solid 2px ${colors.violet};
        content: '';
      }
      
      h4 {
        font-style: italic;
        color: ${colors.white};
        ${sourceCodePro};
        
        font-size: 16px;
        @media screen and (max-width: ${sizesMax.laptop}) {
          font-size: 14px;
        }

        @media screen and (max-width: ${sizesMax.mobileL}) {
          font-size: 14px;
        }
        font-weight: 700;

        strong {
          color: ${colors.lightViolet};
          font-weight: 600;
        }
      }
      
      small {
        margin: 0 0 10px;
        display: block;
        color: ${colors.lightViolet};
        ${sourceCodePro};
        font-size: 12px;
        font-style: italic;
        
        @media screen and (max-width: ${sizesMax.laptop}) {
          font-size: 12px;
        }
        @media screen and (max-width: ${sizesMax.mobileL}) {
          font-size: 12px;
        }
      }
    }
  }
}
`

export const Skills = styled.div`
  ul {
    position: relative;
    z-index: 6;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  li {
    span {
      ${sourceCodePro};
      padding: 20px;

      @media (max-width: ${sizesMax.tablet}) {
        padding: 15px;
      }

      @media (max-width: ${sizesMax.mobileL}) {
        padding: 10px;
      }

      font-weight: 700;
      margin: 0 10px 10px 0;
      color: ${colors.white};
      z-index: 2;
      position: relative;
      display: block;
      z-index: 2;

      font-size: 16px;
      @media screen and (max-width: ${sizesMax.laptop}) {
        font-size: 14px;
      }

      @media screen and (max-width: ${sizesMax.mobileL}) {
        font-size: 14px;
      }
    }

    span:after {
      content: '';
      background: ${colors.shadow};
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: -1;
    }
  }
`
