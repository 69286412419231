// Dependencies
import React from 'react'
import PropTypes from 'prop-types'

// Components
import Reveal from '../Animation/Reveal'

// Styles
import { History } from '../About/About.styled'

const ProfileHistory = ({ title, text, history }) => {
  return (
    <History>
      <div>
        <Reveal>
          <h3>{title}</h3>
        </Reveal>
      </div>
      <br />
      <div>
        <Reveal>
          {text &&
            text.split('\n').map((p, i) => {
              return (
                <p
                  key={'history_p' + i}
                  dangerouslySetInnerHTML={{ __html: p }}
                />
              )
            })}
        </Reveal>
      </div>
      <div>
        <ul>
          {history &&
            history.map((work, index) => {
              return (
                <li key={'history_job' + index}>
                  <Reveal>
                    <small>{work.period}</small>
                  </Reveal>
                  <Reveal>
                    <h4>
                      {work.title} <br />
                      <strong>@ {work.company}</strong>
                    </h4>
                  </Reveal>
                </li>
              )
            })}
        </ul>
      </div>
    </History>
  )
}

// Components PropTypes
ProfileHistory.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  history: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      period: PropTypes.string,
      company: PropTypes.string,
    })
  ),
}
ProfileHistory.defaultProps = {
  history: [],
  text: '',
}

export default ProfileHistory
