// Dependencies
import React from 'react'
import PropTypes from 'prop-types'

// Components
import Reveal from '../Animation/Reveal'
import FadeIn from '../Animation/FadeIn'

// Styles
import { Brands } from '../About/About.styled'

const ProfileBrands = ({ title, text, brands }) => {
  return (
    <Brands>
      <div>
        <Reveal>
          <h3>{title}</h3>
        </Reveal>
      </div>
      <br />
      <div>
        <Reveal>
          {text &&
            text.split('\n').map((p, i) => {
              return (
                <p
                  key={'brand_p' + i}
                  dangerouslySetInnerHTML={{ __html: p }}
                />
              )
            })}
        </Reveal>
      </div>
      <div>
        <ul>
          {brands &&
            brands.map((brand, index) => {
              return (
                <li key={'brand' + index}>
                  <FadeIn>
                    <figure>
                      <div style={{backgroundImage: `url(${brand.file.url})`}} />
                    </figure>
                  </FadeIn>
                </li>
              )
            })}
        </ul>
      </div>
    </Brands>
  )
}

// Components PropTypes
ProfileBrands.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    })
  ),
}
ProfileBrands.defaultProps = {
  brands: [],
  text: '',
}

export default ProfileBrands
